const filesFormats = {
	pdf        : "pdf",
	excel      : [ "csv", "xls", "xlsx" ],
	word       : [ "docx", "doc" ],
	powerPoint : [ "pptx", "ppt" ],
	audio      : [ "wav", "mp3", "M4A" ],
	video      : [ "mp4", "mov", "mmw", "avi" ],
	image      : [ "jpg", "png", "jpeg", "gif" ],
};

export default filesFormats;
