import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	typeComplaints : [],
	typeComplaint  : {},
	page           : 1,
	totalPages     : 1,
};

const TypeComplaintsSlice = createSlice({
	name     : "typeComplaints",
	initialState,
	reducers : {
		setTypeComplaintsData : (state, action) => {
			state.typeComplaints = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addTypeComplaint : (state, action) => {
			state.typeComplaints.push(action?.payload?.data);
		},
		deleteTypeComplaint : (state, action) => {
			const newTypeComplaintData = state.typeComplaints.filter(item => item._id !== action.payload);
			state.typeComplaints = newTypeComplaintData;
		},
	},
});

export default TypeComplaintsSlice;
