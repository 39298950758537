import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	users      : [],
	user       : {},
	page       : 1,
	totalPages : 1,
	filters    : {
		name    : undefined,
		groupId : undefined,
		role    : undefined,
		active  : undefined,
	},
};

const UsersSlice = createSlice({
	name     : "users",
	initialState,
	reducers : {
		setUsersData : (state, action) => {
			state.users = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addUser : (state, action) => {
			state.users.push(action.payload);
		},
		editUser : (state, action) => {
			const findIndexUser = state.users.findIndex(user => user._id === action.payload.userId);
			state.users[findIndexUser] = action.payload.data;
		},
		deleteUser : (state, action) => {
			const newUsersData = state.users.filter(item => item._id !== action.payload);
			state.users = newUsersData;
		},
		setFilters : (state, action) => {
			state.filters = action.payload;
		},
	},
});

export default UsersSlice;
