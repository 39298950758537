import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	complaints : [],
	filters    : {
		start_date : undefined,
		end_date   : undefined,
		mean_id    : {
			name : undefined,
			id   : undefined,
		},
		type_id : {
			name : undefined,
			id   : undefined,
		},
		criticality : undefined,
		company_id  : {
			id   : undefined,
			name : undefined,
		},
		status : undefined,
	},
};

const ReportsSlice = createSlice({
	name     : "complaints",
	initialState,
	reducers : {
		setReportsData : (state, action) => {
			state.complaints = action.payload.data;
		},
		setFilters : (state, action) => {
			state.filters = {...state.filters, ...action.payload};
		},
	},
});

export default ReportsSlice;
