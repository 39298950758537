import { combineReducers }              from "redux";
import { configureStore }               from "@reduxjs/toolkit";
import localForage                      from "localforage";
import { persistStore, persistReducer } from "redux-persist";

// Import Own Components
import { uncapitalize } from "Helpers";
import * as Slices      from "./Slices";
import VuexyReducers    from "./vuexy/rootReducer";

const rootReducer = combineReducers({
	...VuexyReducers,
	...Object
		.entries(Slices)
		.reduce((acc, [key, value]) => ({
			...acc,
			["_" + uncapitalize(key)] : value.reducer,
		}), {}),
});

const persistConfig = {
	key       : "root",
	storage   : localForage,
	whitelist : ["_authSlice", "_preferencesSlice"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const Store = configureStore({
	reducer  : persistedReducer,
	devTools : process?.env?.NODE_ENV !== "production",
});

export const persistor = persistStore(Store);

export default Store;
