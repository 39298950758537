import { createSlice } from "@reduxjs/toolkit";

const initialState = {
	typesUsers : [],
	typeUser   : {},
	page       : 1,
	totalPages : 1,
};

const TypesUsersSlice = createSlice({
	name     : "typeUsersSlice",
	initialState,
	reducers : {
		setTypesUsers : (state, action) => {
			state.typesUsers = action.payload.docs;
			state.page = action.payload.updatedPage;
			state.totalPages = action.payload.totalPages;
		},
		addTypesUsers : (state, action) => {
			state.typesUsers.push(action?.payload?.data);
		},
		deleteTypesUsers : (state, action) => {
			const newTypesUsersData = state.typesUsers.filter(item => item._id !== action.payload);
			state.typesUsers = newTypesUsersData;
		},
	},
});

export default TypesUsersSlice;
