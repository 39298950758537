import Envelope   from "Resources/images/icons/EnvelopeSm.png";
import RingPhone  from "Resources/images/icons/RingPhoneSm.png";
import message    from "Resources/images/icons/SMSSm.png";
import facebook   from "Resources/images/icons/facebook.png";
import WhatsApp   from "Resources/images/icons/whatsApp.png";
import telegram   from "Resources/images/icons/telegram.png";
import homeMail   from "Resources/images/icons/homeMail.png";
import compnay    from "Resources/images/icons/company.png";
import group      from "Resources/images/icons/groupUsers.png";
import webSite    from "Resources/images/icons/webSite.png";
import twitter    from "Resources/images/icons/twitter.png";
import callCenter from "Resources/images/icons/callcenter.png";
import chat       from "Resources/images/icons/wechat.png";
import talk       from "Resources/images/icons/talk.png";
import paper      from "Resources/images/icons/paper.png";
import otherChat  from "Resources/images/icons/chat.png";
import instagram  from "Resources/images/icons/instagram.png";
import mailbox    from "Resources/images/icons/mailbox.png";
import mailsend   from "Resources/images/icons/mailsend.png";
import caller     from "Resources/images/icons/caller.png";
import internet   from "Resources/images/icons/internet.png";
import email      from "Resources/images/icons/email.png";

const meansIcon = [
	Envelope,
	RingPhone,
	message,
	facebook,
	WhatsApp,
	telegram,
	homeMail,
	compnay,
	group,
	webSite,
	twitter,
	callCenter,
	chat,
	talk,
	paper,
	otherChat,
	instagram,
	mailbox,
	mailsend,
	caller,
	internet,
	email,
];

export default meansIcon;
